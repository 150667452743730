import { InputAdornment, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

const SearchBar = ({ placeholder, onChange, label }) => {
  const [inputValue, setInputValue] = useState('');
  const timeoutRef = useRef(null);

  const handleInputChange = (event) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');

    setInputValue(sanitizedValue);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      onChange({ target: { value: sanitizedValue } });
    }, 500);
  };

  return (
    <TextField
      placeholder={placeholder}
      className="customSearchBar"
      label={label}
      fullWidth
      onChange={handleInputChange}
      type="search"
      value={inputValue}
      inputProps={{ maxLength: 100 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default SearchBar;
