import './App.css';
import React, { Suspense, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import globalTheme from './Theme';
import { finalRoutes } from './routes';
import { AlertContext } from './context';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = localStorage.getItem('userId') || null;
  const logoutStatus = localStorage.getItem('logoutStatus');
  const isLogin = localStorage.getItem('isLogin');
  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }
  }, [userId]);

  const [dataAlert, setDataAlert] = useState({
    severity: 'warning',
    message: '',
    open: false,
  });

  const onCloseAlert = () => {
    setDataAlert((prevState) => ({
      ...prevState,
      open: false,
    }));

    if (isLogin) {
      localStorage.removeItem('isLogin');
    }
    if (logoutStatus === 'success') {
      localStorage.removeItem('logoutStatus');
    }
  };

  const value = { dataAlert, setDataAlert, onCloseAlert };

  useEffect(() => {
    if (logoutStatus === 'success') {
      if (location.pathname === '/login') {
        setDataAlert({
          severity: 'success',
          open: true,
          message: 'Logout successful!',
        });
      } else {
        onCloseAlert();
      }
    } else if (isLogin) {
      if (location.pathname === '/workingReport') {
        setDataAlert({
          severity: 'success',
          open: true,
          message: 'Login successful!',
        });
      } else {
        onCloseAlert();
      }
    } else {
      onCloseAlert();
    }
  }, [location, logoutStatus]);

  return (
    <ThemeProvider theme={globalTheme}>
      <AlertContext.Provider value={value}>
        <Suspense
          fallback={
            <p
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
              }}
            >
              Loading...
            </p>
          }
        >
          <Routes>
            {finalRoutes().map((res, idx) => {
              return (
                <Route
                  path={res.path}
                  element={res.element}
                  key={`${idx + 1}-route-path`}
                />
              );
            })}
          </Routes>
        </Suspense>
      </AlertContext.Provider>
    </ThemeProvider>
  );
};

export default App;
