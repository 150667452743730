import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import {
  Typography,
  Button,
  IconButton,
  TextField,
  Autocomplete,
} from '@mui/material';
import SearchBar from '../Searchbar';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import blanktable from '../../assets/blanktable.png';
import '../../App.css';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { EditOutlined, UploadFileOutlined } from '@mui/icons-material';
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const TextFieldDatePicker = (params) => {
  return <TextField {...params} name={params.name} variant="outlined" />;
};
const TextFieldTimePicker = (params) => {
  return <TextField {...params} name={params.name} variant="outlined" />;
};

const DataTable = ({
  title,
  data,
  columns,
  handleChangeSearch,
  handleChangeMonthFilter,
  handleChangeYearFilter,
  handleTimeRangeFilter,
  placeSearch,
  searchTitle,
  onAdd,
  onEdit,
  onUpload,
  onDetail,
  onDelete,
  onFilter,
  totalData,
  onEmployee,
  loading = false,
  dashboard,
  filter,
  projectOptionList,
  handleChangeProjectOptionList,
  getRowHeight,
  isCheckInCardSelected,
}) => {
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [sorting, setSorting] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);

  const changePagination = (model) => {
    setPagination({ ...model });
  };

  const changeSort = (model) => {
    if (model.length > 0) {
      setSorting([{ ...model }]);
    } else {
      setSorting([
        {
          field: '',
          sort: '',
        },
      ]);
    }
  };

  const handleBuildList = (filter) => {
    onFilter(filter);
  };

  useEffect(() => {
    const filter = {
      sorting: sorting.length > 0 ? { ...sorting[0] } : { field: '', sort: '' },
      ...pagination,
    };
    handleBuildList(filter);
  }, [sorting, pagination]);

  useEffect(() => {
    setPagination({ ...pagination, page: 0 });
  }, [filter.search]);

  useEffect(() => {
    const temp = [...columns];
    if (onEdit || onDelete || onDetail)
      temp.push({
        field: 'actions',
        headerName: 'Action',
        minWidth: 100,
        maxWidth: 120,
        sortable: false,
        renderCell: (data) => {
          return (
            <div>
              {onEdit ? (
                <IconButton onClick={() => onEdit(data.id)}>
                  <EditOutlined />
                </IconButton>
              ) : (
                <IconButton onClick={() => onDetail(data.id)}>
                  <PreviewIcon />
                </IconButton>
              )}
              {onDelete && (
                <IconButton onClick={() => onDelete(data.id)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          );
        },
      });
    setDataColumns(temp);
  }, [columns]);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    if (startTime && endTime) {
      handleTimeRangeFilter(startTime, endTime);
    }
  }, [startTime, endTime]);

  useEffect(() => {
    if (!isCheckInCardSelected) {
      setStartTime(null);
      setEndTime(null);
    }
  }, [isCheckInCardSelected]);

  return (
    <Grid container rowSpacing={3}>
      {!dashboard && (
        <Grid item xs={12} pb={2}>
          <Grid
            container
            alignItems="center"
            display="flex"
            className="containerHeader"
          >
            <Grid item>
              <div className="dividerHeader" />
            </Grid>
            <Grid item xs={11} sm={6} paddingX={{ xs: 1, md: 2 }}>
              <Typography
                variant="headerCardMenu"
                fontSize={{ xs: '24px', md: '30px' }}
                padding={0}
                lineHeight="unset"
              >{`Master ${title}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        justifyContent={dashboard ? 'flex-start' : 'space-between'}
        container
        xs={12}
        md={12}
        paddingTop={3}
        spacing={dashboard ? 2 : undefined}
        display={dashboard && 'flex'}
        alignItems={dashboard && 'end'}
      >
        {title !== 'Holiday' ? (
          <>
            <Grid
              item
              xs={12}
              sm={dashboard ? 3 : 6}
              md={dashboard ? 3 : 4}
              alignSelf={dashboard ? 'end' : 'center'}
              sx={{ textAlign: { xs: 'start', sm: 'end' } }}
            >
              <SearchBar
                placeholder={placeSearch}
                label={searchTitle}
                onChange={handleChangeSearch}
              />
            </Grid>
            {dashboard && (
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  disablePortal
                  id="project-list-download-report-in-bulk"
                  name="projectName"
                  options={projectOptionList}
                  getOptionLabel={(option) => option.projectName}
                  onChange={(event, newValue) => {
                    handleChangeProjectOptionList(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="Project Name"
                      placeholder="Select Project"
                      inputProps={{
                        ...params.inputProps,
                        maxLength: 100,
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {dashboard && isCheckInCardSelected && (
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                flexDirection="column"
                gap={1}
              >
                <Typography fontSize={12} color="rgba(0, 0, 0, 0.6)">
                  Filter by Check-In Time
                </Typography>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    display="flex"
                    gap={1}
                    alignItems="center"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        label="Start Time"
                        onChange={(newValue) => {
                          if (newValue) {
                            setStartTime(newValue.format('HH:mm:ss'));
                            setEndTime(null);
                          } else {
                            setStartTime(null);
                          }
                        }}
                        views={['hours', 'minutes', 'seconds']}
                        slots={{
                          textField: TextFieldTimePicker,
                        }}
                        slotProps={{
                          actionBar: {
                            actions: ['clear', 'cancel', 'accept'],
                          },
                        }}
                      />
                    </LocalizationProvider>
                    <Typography>-</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        label="End Time"
                        onChange={(newValue) => {
                          if (newValue) {
                            setEndTime(newValue.format('HH:mm:ss'));
                          } else {
                            setEndTime(null);
                          }
                        }}
                        views={['hours', 'minutes', 'seconds']}
                        minTime={
                          startTime ? dayjs(startTime, 'HH:mm:ss') : null
                        }
                        slots={{
                          textField: TextFieldTimePicker,
                        }}
                        slotProps={{
                          actionBar: {
                            actions: ['clear', 'cancel', 'accept'],
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Grid
            container
            direction="row"
            item
            xs={12}
            sm={6}
            alignItems="center"
            justifyContent="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={8} md={5} lg={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={['month']}
                  openTo="month"
                  name="month"
                  label="Select Month"
                  inputFormat="MM"
                  onChange={handleChangeMonthFilter}
                  slots={{
                    textField: TextFieldDatePicker,
                  }}
                  slotProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={8} md={5} lg={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={['year']}
                  openTo="year"
                  name="year"
                  label="Select Year"
                  inputFormat="YYYY"
                  defaultValue={dayjs(new Date().getFullYear().toString())}
                  onChange={handleChangeYearFilter}
                  slots={{
                    textField: TextFieldDatePicker,
                  }}
                  slotProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        )}

        {onEmployee && (
          <Grid
            item
            xs={12}
            sm={4}
            mt={{ xs: 1, sm: 0 }}
            alignSelf="center"
            sx={{ textAlign: { xs: 'start', sm: 'end' } }}
          >
            <Button
              variant="contained"
              className="button-text"
              onClick={() => onEmployee()}
              startIcon={<SyncOutlinedIcon />}
              sx={{ width: { xs: '100%', sm: 'unset' } }}
            >
              Synchronise
            </Button>
          </Grid>
        )}

        {onUpload && (
          <Grid
            container
            direction="row"
            item
            xs={12}
            sm={6}
            mt={{ xs: 1, sm: 0 }}
            gap={1}
            alignItems="center"
            sx={{ justifyContent: { xs: 'start', sm: 'end' } }}
          >
            {onAdd && (
              <Button
                variant="contained"
                onClick={() => onAdd()}
                startIcon={<AddIcon />}
                sx={{ width: { xs: '100%', sm: 'unset' } }}
              >
                {title}
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => onUpload()}
              startIcon={<UploadFileOutlined />}
              sx={{ paddingY: 1, width: { xs: '100%', sm: 'unset' } }}
            >
              Upload {title}
            </Button>
          </Grid>
        )}

        {!onEmployee && !onUpload && !dashboard && (
          <Grid
            item
            xs={12}
            sm={4}
            mt={{ xs: 1, sm: 0 }}
            alignSelf="center"
            sx={{ textAlign: { xs: 'start', sm: 'end' } }}
          >
            {onAdd && (
              <Button
                variant="contained"
                onClick={() => onAdd()}
                startIcon={<AddIcon />}
                sx={{ width: { xs: '100%', sm: 'unset' } }}
              >
                {title}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      {data.length > 0 ? (
        <Grid item xs={12}>
          <DataGrid
            rows={data}
            columns={dataColumns}
            disableRowSelectionOnClick
            pageSizeOptions={[10, 25, 50, 100]}
            paginationMode="server"
            paginationModel={{ ...pagination }}
            onPaginationModelChange={(model) => changePagination(model)}
            onSortModelChange={(model) => changeSort(model)}
            disableColumnFilter
            loading={loading}
            disableColumnMenu
            rowCount={totalData}
            getRowId={(row) => row.id}
            sortingMode="server"
            getRowHeight={getRowHeight || undefined}
          />
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          minHeight="600px"
          alignContent="center"
          alignItems="center"
          display="flex"
          justifyContent="center"
          textAlign="center"
        >
          <Grid item xs={12} pb={3.75}>
            <img
              src={blanktable}
              alt="blank-table"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="noDataTable">
              Sorry, the data you are looking for could not be found.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

DataTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  columns: PropTypes.arrayOf(PropTypes.object),
  handleChangeSearch: PropTypes.func,
  handleChangeMonthFilter: PropTypes.func,
  handleChangeYearFilter: PropTypes.func,
  handleTimeRangeFilter: PropTypes.func,
  placeSearch: PropTypes.string,
  searchTitle: PropTypes.string,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onUpload: PropTypes.func,
  onDetail: PropTypes.func,
  onDelete: PropTypes.func,
  onFilter: PropTypes.func,
  totalData: PropTypes.number,
  onEmployee: PropTypes.func,
  loading: PropTypes.bool,
  dashboard: PropTypes.bool,
  filter: PropTypes.object,
  isCheckInCardSelected: PropTypes.bool,
};

export default DataTable;
