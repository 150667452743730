import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

function BreadCumbComp({ breadcrumbs }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const currentIndex = breadcrumbs.findIndex(
    (breadcrumb) => breadcrumb.current
  );

  const filteredBreadcrumbs =
    currentIndex !== -1 ? breadcrumbs.slice(0, currentIndex + 1) : breadcrumbs;

  const navigateUrl = (url) => {
    navigate(url);
  };

  return (
    <div>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: '20px' }}
      >
        {filteredBreadcrumbs.map((res, index) => (
          <Link
            underline="hover"
            key={index + 1}
            color="inherit"
            onClick={() => navigateUrl(res.href)}
            // href={res.href}
            style={{
              fontSize: '100%',
              color: res.current && '#2196F3',
              cursor: 'pointer',
            }}
          >
            {res.current
              ? res.title
              : isXs
                ? res.title.slice(0, 5) + '...'
                : res.title}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
}
BreadCumbComp.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      current: PropTypes.bool.isRequired,
    })
  ).isRequired,
};
export default BreadCumbComp;
