import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EditParameterConfigurationSchema } from '../schema';
import { AlertContext } from '../../../context';
import client from '../../../global/client';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const EditParameterConfig = ({ open, setOpen, onSaveSuccess, parameterId }) => {
  const { setDataAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState();
  const [openValidation, setOpenValidation] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onSave = useCallback(async (data) => {
    setLoading(true);

    const updateData = {
      value: data.value,
      key: data.key,
      description: data.description,
      projectId: data.project.id,
    };

    const res = await client.requestAPI({
      method: 'PUT',
      endpoint: `/workingReportConfig/${parameterId}`,
      data: updateData,
    });

    if (!res.isError) {
      setDataAlert({
        severity: 'success',
        open: true,
        message: res.data.meta.message,
      });

      onSaveSuccess('edit');
      setOpenValidation(false);
      reset();
    } else {
      setDataAlert({
        severity: 'error',
        open: true,
        message: 'Failed to edit the configuration',
      });
    }
    setLoading(false);
  }, []);

  const getParameterData = useCallback(async () => {
    const res = await client.requestAPI({
      method: 'GET',
      endpoint: `/workingReportConfig/${parameterId}`,
    });

    if (!res.isError) {
      const data = res.data.attributes;
      setValue('key', data.key);
      setValue('project', { id: data.projectId, name: data.projectName });
      setValue('value', data.value);
      setValue('description', data.description);
      setDefaultValue({
        key: data.key,
        project: { id: data.projectId, name: data.projectName },
        value: data.value,
        description: data.description,
      });
    }
  }, [parameterId]);

  const { control, handleSubmit, formState, reset, setValue, getValues } =
    useForm({
      resolver: yupResolver(EditParameterConfigurationSchema),
      mode: 'onBlur',
    });

  useEffect(() => {
    getParameterData();
  }, [getParameterData]);

  if (!defaultValue) {
    return (
      <p
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        Loading...
      </p>
    );
  } else {
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="dialog-delete dialog-task"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title" className="dialog-delete-header">
            Edit the Configuration Data
          </DialogTitle>
          <DialogContent className="dialog-task-content">
            <form>
              <Grid container rowSpacing={2}>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="key"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          disabled
                          label="Key Name"
                          value={field.value || '-'}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="project"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          disabled
                          label="Project Name"
                          value={field.value.name || '-'}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="value"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          {...field}
                          label="Value Time"
                          slotProps={{
                            textField: {
                              required: true,
                              fullWidth: true,
                              placeholder: 'HH:MM',
                            },
                          }}
                          value={
                            field.value ? dayjs(field.value, 'HH:mm:ss') : null
                          }
                          onChange={(e) => {
                            field.onChange(e ? e.format('HH:mm:ss') : '');
                          }}
                          ampm={false}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        onChange={field.onChange}
                        className="input-field-crud"
                        placeholder="e.g Employee's last limit for check-in in 24-hour format"
                        label="Description"
                        multiline
                        maxRows={4}
                        required
                        inputProps={{ maxLength: 255 }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  className="button-text"
                  onClick={handleClose}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className="button-text"
                  type="submit"
                  disabled={!formState.isValid || loading}
                  onClick={() => setOpenValidation(true)}
                >
                  Save Data
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openValidation}
          onClose={() => setOpenValidation(false)}
          aria-labelledby="validation-dialog-title"
          aria-describedby="validation-dialog-description"
          className="dialog-delete"
          fullWidth
        >
          <DialogTitle
            id="validation-dialog-title"
            className="dialog-delete-header"
          >
            Save Data
          </DialogTitle>
          <DialogContent className="dialog-task-content">
            <DialogContentText
              className="dialog-delete-text-content"
              id="validation-dialog-description"
            >
              Save your progress: Don't forget to save your data before leaving
            </DialogContentText>
            <DialogActions>
              <Grid container justifyContent="center" spacing={2} marginTop={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    className="button-text"
                    onClick={() => setOpenValidation(false)}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className="button-text"
                    onClick={handleSubmit(onSave)}
                    disabled={loading}
                  >
                    {loading ? (
                      <React.Fragment>
                        <CircularProgress size={14} />
                        <Typography marginLeft={1}>Loading...</Typography>
                      </React.Fragment>
                    ) : (
                      'Save Data'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
};
export default EditParameterConfig;
