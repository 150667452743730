import React from 'react';
import { Hidden, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

const Index = (props) => {
  const { judul } = props;
  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Grid container className="containerHeader">
          <Grid item>
            <div className="dividerHeader" />
          </Grid>
          <Grid
            item
            xs={11}
            paddingX={{ xs: 1, md: 2 }}
            alignItems="center"
            display="flex"
          >
            <Typography
              variant="headerCardMenu"
              fontSize={{ xs: '24px', md: '30px' }}
              padding={0}
              lineHeight="unset"
            >
              {`${judul}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Index.propTypes = {
  judul: PropTypes.string,
};

export default Index;
