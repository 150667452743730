import React, { useContext } from 'react';
import DeleteDialog from '../../../Component/DialogDelete';
import client from '../../../global/client';
import { AlertContext } from '../../../context';

const DeleteParameterConfig = ({
  open,
  handleClose,
  parameterId,
  onSaveSuccess,
}) => {
  const { setDataAlert } = useContext(AlertContext);

  const onDelete = async (id) => {
    const res = await client.requestAPI({
      method: 'DELETE',
      endpoint: `/workingReportConfig/${id}`,
    });

    if (!res.isError) {
      setDataAlert({
        severity: 'success',
        open: true,
        message: res.meta.message,
      });
      onSaveSuccess('delete');
    } else {
      setDataAlert({
        severity: 'error',
        open: true,
        message: 'Failed to delete the configuration',
      });
    }
  };

  return (
    <DeleteDialog
      dialogOpen={open}
      handleClose={handleClose}
      deleteData={onDelete}
      id={parameterId}
    />
  );
};

export default DeleteParameterConfig;
