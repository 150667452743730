import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ParameterConfigurationSchema } from '../schema';
import { AlertContext } from '../../../context';
import client from '../../../global/client';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CreateParameterConfig = ({ open, setOpen, onSaveSuccess }) => {
  const { setDataAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onSave = useCallback(async (data) => {
    setLoading(true);
    const createData = {
      value: data.time,
      key: `${data.type.typeCode}_${data.project.projectName
        .replaceAll(/\,\./gi, '')
        .replaceAll(/\s/gi, '-')
        .toUpperCase()}`,
      description: data.description,
      projectId: data.project.id,
    };

    const res = await client.requestAPI({
      method: 'POST',
      endpoint: '/workingReportConfig/create',
      data: createData,
    });

    if (!res.isError) {
      setDataAlert({
        severity: 'success',
        open: true,
        message: res.data.meta.message,
      });

      onSaveSuccess('add');
      setOpenValidation(false);
      reset();
    } else {
      setDataAlert({
        severity: 'error',
        open: true,
        message: 'Failed to create the configuration',
      });
    }
    setLoading(false);
  }, []);

  const CI_CO_TYPE = [
    { id: 1, typeName: 'Check-In', typeCode: 'CI' },
    { id: 2, typeName: 'Check-Out', typeCode: 'CO' },
  ];

  const [projectOptionList, setProjectOptionList] = useState([]);

  const rebuildProjectOptionListData = useCallback((resData) => {
    const temp = resData.data.map((item) => ({
      id: item.id,
      projectName: item.attributes.name,
    }));

    setProjectOptionList((prevList) => [...prevList, ...temp]);
  }, []);

  const getProjectOptionList = useCallback(async () => {
    const res = await client.requestAPI({
      method: 'GET',
      endpoint: '/ol/project?search=',
    });

    if (!res.isError) {
      rebuildProjectOptionListData(res);
    }
  }, [rebuildProjectOptionListData]);

  const defaultValues = {
    type: null,
    project: null,
    time: null,
    description: '',
  };

  const { control, handleSubmit, formState, reset } = useForm({
    defaultValues,
    resolver: yupResolver(ParameterConfigurationSchema),
    mode: 'onBlur',
  });

  useEffect(() => {
    getProjectOptionList();
  }, [getProjectOptionList]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-delete dialog-task"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className="dialog-delete-header">
          Create a New CI/CO Configuration
        </DialogTitle>
        <DialogContent className="dialog-task-content">
          <form>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        value={field.value || null}
                        disablePortal
                        id="option-list-ci-co-type-parameter-config"
                        options={CI_CO_TYPE}
                        getOptionLabel={(option) => option.typeName}
                        onChange={(_, newValue) => field.onChange(newValue)}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            InputLabelProps={{ shrink: true }}
                            label="Key Type"
                            placeholder="Select the key type"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="project"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        value={field.value || null}
                        disablePortal
                        id="option-list-project-parameter-config"
                        options={projectOptionList}
                        getOptionLabel={(option) => option.projectName}
                        onChange={(_, newValue) => field.onChange(newValue)}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            InputLabelProps={{ shrink: true }}
                            label="Project Name"
                            placeholder="Select the project"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="time"
                  control={control}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        {...field}
                        label="Value Time"
                        autoFocus
                        slotProps={{
                          textField: {
                            required: true,
                            fullWidth: true,
                            placeholder: 'HH:MM',
                          },
                        }}
                        value={
                          field.value ? dayjs(field.value, 'HH:mm:ss') : null
                        }
                        onChange={(e) => {
                          field.onChange(e ? e.format('HH:mm:ss') : '');
                        }}
                        ampm={false}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value}
                      onChange={field.onChange}
                      className="input-field-crud"
                      placeholder="e.g Employee's last limit for check-in in 24-hour format"
                      label="Description"
                      multiline
                      maxRows={4}
                      required
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                className="button-text"
                onClick={handleClose}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className="button-text"
                type="submit"
                disabled={!formState.isValid || loading}
                onClick={() => setOpenValidation(true)}
              >
                Save Data
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openValidation}
        onClose={() => setOpenValidation(false)}
        aria-labelledby="validation-dialog-title"
        aria-describedby="validation-dialog-description"
        className="dialog-delete"
        fullWidth
      >
        <DialogTitle
          id="validation-dialog-title"
          className="dialog-delete-header"
        >
          Save Data
        </DialogTitle>
        <DialogContent className="dialog-task-content">
          <DialogContentText
            className="dialog-delete-text-content"
            id="validation-dialog-description"
          >
            Save your progress: Don't forget to save your data before leaving
          </DialogContentText>
          <DialogActions>
            <Grid container justifyContent="center" spacing={2} marginTop={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  className="button-text"
                  onClick={() => setOpenValidation(false)}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className="button-text"
                  onClick={handleSubmit(onSave)}
                  disabled={loading}
                >
                  {loading ? (
                    <React.Fragment>
                      <CircularProgress size={14} />
                      <Typography marginLeft={1}>Loading...</Typography>
                    </React.Fragment>
                  ) : (
                    'Save Data'
                  )}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateParameterConfig;
