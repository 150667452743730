import * as yup from 'yup';

const ParameterConfigurationSchema = yup
  .object({
    type: yup
      .object({
        id: yup.number().required(),
        typeName: yup.string().required(),
        typeCode: yup.string().required(),
      })
      .required('Please select the CI/CO type!'),
    project: yup
      .object({
        id: yup.number().required(),
        projectName: yup.string().required(),
      })
      .required('Please select the project'),
    time: yup.string().required('Please select the time'),
    description: yup.string().required('Please fill the description'),
  })
  .required('Please fill all the fields!');

const EditParameterConfigurationSchema = yup
  .object({
    value: yup.string().required(),
    key: yup.string().required('Please select the time'),
    project: yup
      .object({
        id: yup.number().nullable(),
        name: yup.string().nullable(),
      })
      .nullable(),
    description: yup.string().required('Please fill the description'),
  })
  .required('Please fill all the fields!');

export { ParameterConfigurationSchema, EditParameterConfigurationSchema };
