import React, { useCallback, useContext, useEffect, useState } from 'react';
import SideBar from '../../Component/Sidebar';
import DataTable from '../../Component/DataTable';
import { Box } from '@mui/system';
import CreateParameterConfig from './Create';
import client from '../../global/client';
import { AlertContext } from '../../context';
import EditParameterConfig from './Edit';
import DeleteParameterConfig from './Delete';

export default function ParameterConfiguration() {
  const columns = [
    {
      field: 'no',
      headerName: 'No',
      maxWidth: 50,
      sortable: false,
    },
    {
      field: 'key',
      headerName: 'Key',
      sortable: true,
      minWidth: 180,
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Value',
      sortable: false,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Box
          maxWidth="100%"
          sx={{ overflowWrap: 'break-word' }}
          flexWrap="wrap"
        >
          <p className="text-name" key={params.id}>
            {params.value}
          </p>
        </Box>
      ),
    },
    {
      field: 'p.projectName',
      headerName: 'Project Name',
      sortable: true,
      flex: 1,
      minWidth: 180,
    },
  ];

  const { setDataAlert } = useContext(AlertContext);
  const [parameterList, setParameterList] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sortName: '',
    sortType: '',
    search: '',
  });

  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const onFilter = (dataFilter) => {
    setFilter({
      page: dataFilter.page,
      size: dataFilter.pageSize,
      sortName:
        dataFilter.sorting.field !== '' ? dataFilter.sorting[0].field : '',
      sortType:
        dataFilter.sorting.sort !== '' ? dataFilter.sorting[0].sort : '',
      search: filter.search,
    });
  };

  const rebuildData = useCallback(
    (resData) => {
      let temp = [];
      let number = filter.page * filter.size;
      temp = resData.data.map((value, index) => {
        return {
          no: number + index + 1,
          id: value.id,
          'p.projectName': value.attributes.projectName || '-',
          key: value.attributes.key,
          value: value.attributes.value,
          description: value.attributes.description || '-',
        };
      });

      setParameterList([...temp]);
      setTotalData(resData.meta.page.totalElements);
    },
    [filter.page, filter.size]
  );

  const getParameterData = useCallback(async () => {
    setLoading(true);
    const res = await client.requestAPI({
      method: 'GET',
      endpoint: `/workingReportConfig?page=${filter.page}&size=${filter.size}&sort=${filter.sortName},${filter.sortType}&search=${filter.search}`,
    });

    if (!res.isError) {
      rebuildData(res);
    } else {
      setDataAlert({
        severity: 'error',
        message: res.error.detail,
        open: true,
      });
    }
    setLoading(false);
  }, [filter, rebuildData]);

  const handleChangeSearch = (event) => {
    setFilter({ ...filter, search: event.target.value });
  };

  const handleAdd = () => {
    setOpenAdd(true);
  };

  const handleEdit = (value) => {
    setSelectedId(value);
    setOpenEdit(true);
  };

  const handleDelete = (value) => {
    setSelectedId(value);
    setOpenDelete(true);
  };

  const onSaveSuccess = (action) => {
    if (action === 'add') {
      setOpenAdd(false);
    } else if (action === 'edit') {
      setOpenEdit(false);
    } else if (action === 'delete') {
      setOpenDelete(false);
    }
    getParameterData();
  };

  useEffect(() => {
    getParameterData();
  }, [getParameterData]);

  return (
    <SideBar>
      <DataTable
        title="CI/CO Configuration"
        data={parameterList}
        columns={columns}
        searchTitle="Search By"
        placeSearch="Key, Description"
        handleChangeSearch={handleChangeSearch}
        totalData={totalData}
        filter={filter}
        onFilter={onFilter}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleDelete}
        loading={loading}
        getRowHeight={() => 'auto'}
      />
      <CreateParameterConfig
        open={openAdd}
        setOpen={setOpenAdd}
        onSaveSuccess={onSaveSuccess}
      />
      {selectedId && openEdit && (
        <EditParameterConfig
          open={openEdit}
          setOpen={setOpenEdit}
          onSaveSuccess={onSaveSuccess}
          parameterId={selectedId}
        />
      )}
      {selectedId && openDelete && (
        <DeleteParameterConfig
          open={openDelete}
          handleClose={() => {
            setOpenDelete(false);
          }}
          parameterId={selectedId}
          onSaveSuccess={onSaveSuccess}
        />
      )}
    </SideBar>
  );
}
